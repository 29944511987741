@import '../../mixins';

.request-call {
  & .modal-action {
    margin-top: 0;
    padding-top: 0;

    &::before {
      display: none;
    }
  }
}
